import { useCallback, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { app } from "./firebaseConfig";
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Inter:400,500,600,700,800,900', 'sans-serif']
  }
});


function App() {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);


  useEffect(() => {
    // const ref = app.database().ref('/lifts/');

    // ref.on('value', (snapshot) => {
    //   const data = snapshot.val();
    //   console.log('Received data:', data);
    // });

    // return () => {
    //   ref.off();  // Clean up listener
    // };
  }, []);


  return (
    <div style={{height: "100%", width: "100%", position: "absolute"}}>
      <Particles id="tsparticles" url="/particlesjs-config.json" init={particlesInit} loaded={particlesLoaded} />
      <div className="text-container">
        <h1 className="center">Liftie</h1>
        <span className="center tagline">Winter 2024</span>
      </div>
    </div>
  );
}

export default App;
