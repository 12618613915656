import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA1a4iZgqOX6O5koby6lQIBYOeqsy7q3KM",
    authDomain: "liftie-app.firebaseapp.com",
    databaseURL: "https://liftie-app-default-rtdb.firebaseio.com",
    projectId: "liftie-app",
    storageBucket: "liftie-app.appspot.com",
    messagingSenderId: "122393286817",
    appId: "1:122393286817:web:f8df68cbc89eee06f0b8e0",
    measurementId: "G-TRHCCH7X2Z"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);


